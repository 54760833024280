import { useFirebase } from "@/composables/firebase";
const { onAuthStateChanged, user, auth, customer, getUserIdToken, signIn } =
    useFirebase();
export default defineNuxtRouteMiddleware(async (to, from) => {
    if (import.meta.server) return;
    if (to.path === "/login") return;

    // Show loader
    if (import.meta.client) {
        window.onNuxtLoading?.(true);
    }

    try {
        const authCheck = new Promise((resolve) => {
            onAuthStateChanged(auth, async (userData: any) => {
                if (!userData) {
                    await signIn(); // Sign in anonymously
                    return resolve(true);
                } else {
                    console.log("User is logged in", user.value);
                    console.log("Customer", customer.value);
                    return resolve(true); // User is logged in
                }
            });
        });

        const isUserAuthenticated = await authCheck;

        if (!isUserAuthenticated) {
            return navigateTo("/login");
        }
    } finally {
        // Hide loader
        if (import.meta.client) {
            window.onNuxtLoading?.(false);
        }
    }
});
